<template>
    <div class="page-prepay-mobile-preactivation-previous-reservations">
        <app-loader v-if="loading" fixed-on="desktop" />

        <app-error v-model="errors.show" :message="errors.message" />

        <app-table
            class="previous-reservations-table"

            :sort-value="filter.sort"
            :sort-options="sort"
            @sort-change="onSortChange"

            :clickable="true"

            :cols="cols"
            :rows="rows"

            @row-click="showReservedMsisdnsDialog"
            @row-click-mobile="showReservedMsisdnsDialog"

            @remove-reservation="showCancelReservationDialog"
        />

        <app-pagination
            v-if="pagination.total"

            v-model="pagination.page"

            :total="pagination.total"
            :limit="pagination.limit"
            :page-range="pagination.range"

            @change="onPageChange"
        />

        <app-dialog-reserved-msisdns
            v-if="dialogs.reserved_msisdns.show"
            v-model="dialogs.reserved_msisdns.show"

            :uuid="dialogs.reserved_msisdns.uuid"

            :show-buttons="false"
        />

        <app-dialog-cancel-reservation
            v-if="dialogs.cancel_reservation.show"
            v-model="dialogs.cancel_reservation.show"

            :uuid="dialogs.cancel_reservation.uuid"

            @confirm="onDialogCancelReservationConfirm"
        />
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import appDialogReservedMsisdns from '../components/app-dialog-reserved-msisdns'
import appDialogCancelReservation from '../components/app-dialog-cancel-reservation'

import errMessage from '@/helpers/errMessage'

import TEST_RESERVATIONS from './test-reservations.json'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appPagination,

        appDialogReservedMsisdns,
        appDialogCancelReservation,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'AccountName',
                    order: 'asc',
                },
            },

            sort: [
                { field: 'AccountName',       title: 'Account Name'        },
                { field: 'IMSIStart',        title: 'IMSI Start'         },
                { field: 'NumberToAllocate', title: 'Number to allocate' },
            ],

            cols: [
                { key: 'AccountName',       title: 'Account Name',        sort: { field: 'AccountName'       } },
                { key: 'IMSIStart',        title: 'IMSI Start',         sort: { field: 'IMSIStart'        } },
                { key: 'NumberToAllocate', title: 'Number to allocate', sort: { field: 'NumberToAllocate' } },
                { key: 'ReservedMSISDNs',  title: 'Reserved MSISDNs'                                        },

                { key: 'remove', action: 'remove-reservation', icon: 'remove', behavior: 'detached' },
            ],

            reservations: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                reserved_msisdns: {
                    show: false,

                    uuid: null,
                },

                cancel_reservation: {
                    show: false,

                    uuid: null,
                },
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getReservations()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getReservations()
        },

        onPageChange(page) {
            this.getReservations(page)
        },

        getReservations(page) {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                const list = []

                const start = ( ( page || this.pagination.page ) - 1 ) * this.pagination.limit
                const total = TEST_RESERVATIONS.length
                const end = start + this.pagination.limit < total ? start + this.pagination.limit : total

                for (let i = start; i < end; i++) {
                    list.push(TEST_RESERVATIONS[i])
                }

                this.reservations = list

                this.pagination.page = page || this.pagination.page
                this.pagination.total = total

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.errors = {
                    show: false,
                    message: '',
                }

                this.loading = false
            }, delay_in_ms)
        },

        showReservedMsisdnsDialog(row) {
            this.dialogs.reserved_msisdns.show = true

            this.dialogs.reserved_msisdns.uuid = row.uuid
        },

        showCancelReservationDialog(row) {
            this.dialogs.cancel_reservation.show = true

            this.dialogs.cancel_reservation.uuid = row.uuid
        },
        onDialogCancelReservationConfirm() {
            this.dialogs.cancel_reservation.show = false

            this.dialogs.cancel_reservation.uuid = null

            this.getReservations()
        },
    },

    computed: {
        rows() {
            const rows = []

            for (let i = 0, len = this.reservations.length; i < len; i++) {
                const reservation = this.reservations[i]

                rows.push({
                    uuid: reservation.UUID,

                    AccountName: `${reservation.FirstName} ${reservation.LastName}`,
                    IMSIStart: reservation.IMSIStart,
                    NumberToAllocate: reservation.ReservedMSISDNs.length,
                    ReservedMSISDNs: reservation.ReservedMSISDNs.join(', '),
                })
            }

            return rows
        },
    },
}
</script>

<style lang="scss">
.page-prepay-mobile-preactivation-previous-reservations {
    .app-error {
        margin-bottom: 24px;
    }

    .previous-reservations-table {
        @include table-cols-width((180px, 150px, 180px, 500px, 48px), true, 1);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-prepay-mobile-preactivation-previous-reservations {
        .previous-reservations-table {
            @include table-cols-width((150px, 130px, 150px, 400px, 48px), true, 1);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-prepay-mobile-preactivation-previous-reservations {
        .previous-reservations-table {
            @include table-cols-width-mobile((100px, 150px, 24px), true);
        }
    }
}
</style>